import type { ComponentProps } from 'react'
import { cn } from '#lib/utils.js'

export const Logo = ({ className, ...props }: ComponentProps<'svg'>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1144.77 219.96"
      className={cn('w-full h-full', className)}
      {...props}
    >
      <title>Logo</title>
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="-55.64"
          y1="214.57"
          x2="205.14"
          y2="94.08"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#ff2e92" />
          <stop offset="1" stopColor="#ff31c5" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="-75.57"
          y1="171.44"
          x2="185.21"
          y2="50.95"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-3"
          x1="-53.18"
          y1="219.91"
          x2="207.6"
          y2="99.42"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="-75.97"
          y1="170.56"
          x2="184.8"
          y2="50.08"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="59.79"
          y1="20.42"
          x2="115.26"
          y2="20.42"
          xlinkHref="#linear-gradient"
        />
      </defs>
      <g>
        <g>
          <g className="fill-current stroke-none">
            <path d="m272.99,94.44V12.56h-23.23v155.55h26.36v-73.67h-3.14Zm83.34-2.44c-.47-4.5-1.48-9.28-3.06-14.3-1.6-5.05-4.03-9.8-7.35-14.26-3.31-4.46-7.79-8.14-13.41-11.03-5.61-2.86-12.74-4.31-21.39-4.31-10.95,0-20.17,2.38-27.65,7.17-4.01,2.57-7.5,5.61-10.48,9.14-2.57,3.02-4.74,6.41-6.53,10.15-3.86,8.07-5.79,17.07-5.79,27.01l15.45,6.59c0-7.13.8-12.98,2.38-17.56,1.58-4.58,3.68-8.16,6.31-10.8,2.63-2.63,5.57-4.48,8.81-5.57,3.23-1.07,6.53-1.62,9.84-1.62,6.2,0,11.11,1.33,14.73,3.99,3.64,2.67,6.39,6.08,8.22,10.21,1.83,4.15,3,8.44,3.51,12.92.51,4.46.76,8.53.76,12.2v56.18h26.36v-65.14c0-2.81-.23-6.47-.72-10.97Z" />
            <path d="m474.34,82.49c-.43-4.27-1.7-8.48-3.78-12.59-3.82-7.7-9.66-13.25-17.56-16.62-7.87-3.39-17.01-5.09-27.38-5.09-13.76,0-24.69,3-32.79,8.96-8.11,5.98-13.58,13.91-16.47,23.77l23.75,7.44c1.95-5.96,5.38-10.17,10.27-12.63,4.91-2.46,9.98-3.68,15.24-3.68,8.71,0,14.91,1.91,18.57,5.73,3.06,3.18,4.81,7.81,5.28,13.93-4.72.7-9.29,1.36-13.76,2.01-7.39,1.05-14.22,2.18-20.52,3.41-6.31,1.21-11.79,2.59-16.47,4.09-6.2,2.08-11.24,4.77-15.14,8.05-3.88,3.27-6.76,7.17-8.63,11.67-1.87,4.5-2.81,9.57-2.81,15.18,0,6.41,1.48,12.28,4.48,17.61,2.98,5.32,7.39,9.59,13.23,12.8,5.83,3.2,12.96,4.79,21.38,4.79,10.52,0,19.29-1.95,26.32-5.88,5.4-3.02,10.29-7.37,14.63-13.07v15.72h22.78v-71.63c0-5.03-.21-9.7-.64-13.99Zm-25.43,37.39c-.19,4.27-.95,8.07-2.34,11.38-.86,2.67-2.53,5.51-5.01,8.53-2.49,3.04-5.83,5.59-10,7.68-4.19,2.08-9.22,3.14-15.12,3.14-4.11,0-7.56-.64-10.33-1.95-2.77-1.29-4.87-3.04-6.31-5.24-1.44-2.2-2.16-4.73-2.16-7.62,0-2.51.57-4.72,1.68-6.59,1.11-1.87,2.71-3.53,4.81-4.97,2.08-1.44,4.64-2.69,7.66-3.78,3.1-1.01,6.66-1.93,10.7-2.75,4.03-.84,8.87-1.71,14.48-2.65,3.59-.6,7.66-1.27,12.18-1.99-.06,1.97-.14,4.25-.23,6.8Z" />
            <path d="m594.9,78.19c-4.46-9.26-10.74-16.56-18.84-21.94-8.11-5.36-17.67-8.05-28.68-8.05s-20.4,2.69-27.92,8.05c-1.54,1.09-3,2.28-4.38,3.57v-8.38h-23.01v168.53h26.27v-57.74c.47.33.94.68,1.4,1.01,7.7,5.42,17.24,8.11,28.62,8.11,10.74,0,20.09-2.69,28.08-8.11,8.01-5.4,14.19-12.74,18.59-22.04,4.38-9.28,6.59-19.76,6.59-31.43s-2.24-22.35-6.7-31.59Zm-24.03,50.97c-2.12,5.81-5.4,10.39-9.78,13.78s-9.98,5.09-16.74,5.09-12.63-1.6-16.91-4.81c-4.29-3.2-7.42-7.68-9.39-13.45-1.99-5.77-2.98-12.43-2.98-19.99s.99-14.3,2.98-20.03c1.97-5.73,5.05-10.19,9.24-13.41,4.17-3.2,9.57-4.79,16.19-4.79,7,0,12.76,1.7,17.28,5.12,4.54,3.43,7.89,8.03,10.05,13.83,2.16,5.79,3.23,12.22,3.23,19.27s-1.05,13.6-3.18,19.39Z" />
            <path d="m708.39,118.21c-2.47-4.25-6.51-7.91-12.1-10.97-5.57-3.06-13.02-5.79-22.29-8.16-9.08-2.3-15.9-4.25-20.48-5.83-4.58-1.58-7.62-3.18-9.12-4.75-1.52-1.58-2.28-3.49-2.28-5.73,0-3.96,1.87-7.03,5.61-9.24,3.74-2.18,8.69-3.12,14.81-2.75,6.41.35,11.54,1.97,15.39,4.85,3.86,2.88,6.1,6.82,6.74,11.79l26.58-4.75c-.99-7-3.66-13.07-7.99-18.26-4.33-5.18-10-9.2-17.01-12-7.01-2.81-15.08-4.21-24.14-4.21s-17.36,1.46-24.2,4.38c-6.84,2.9-12.18,7.07-16,12.47-3.82,5.4-5.71,11.73-5.71,19.02,0,5.83,1.25,10.8,3.78,14.91,2.51,4.11,6.64,7.66,12.41,10.64,5.77,2.98,13.54,5.77,23.34,8.38,8.79,2.3,15.3,4.21,19.54,5.71,4.25,1.52,7.01,3.08,8.26,4.72,1.27,1.62,1.89,3.76,1.89,6.41,0,4.33-1.71,7.74-5.12,10.21-3.43,2.49-8.26,3.74-14.54,3.74-7.13,0-13.04-1.66-17.71-4.97-4.68-3.31-7.7-7.85-9.08-13.62l-26.58,4.11c1.95,11.73,7.42,20.85,16.43,27.32,9,6.49,20.71,9.72,35.11,9.72s26.85-3.35,35.39-10.04c8.53-6.7,12.8-15.92,12.8-27.67,0-6.04-1.25-11.18-3.74-15.43Z" />
            <path d="m754.89,71.85v47.1c0,4.7.06,8.79.16,12.31.12,3.55.86,6.41,2.22,8.65,2.59,4.33,6.72,6.74,12.41,7.23,5.69.51,12.18.23,19.45-.86v21.82c-7.7,1.44-15.24,2.07-22.62,1.89-7.38-.18-13.99-1.54-19.82-4.11-5.85-2.55-10.27-6.61-13.29-12.14-2.67-5.05-4.07-10.19-4.23-15.45-.14-5.26-.21-11.2-.21-17.83V19.03h25.93v32.4h34.24v20.42h-34.24Z" />
            <path d="m899.22,82.49c-.45-4.27-1.69-8.48-3.78-12.59-3.82-7.7-9.68-13.25-17.56-16.62-7.89-3.39-17.01-5.09-27.4-5.09-13.76,0-24.69,3-32.77,8.96-8.11,5.98-13.6,13.91-16.48,23.77l23.77,7.44c1.95-5.96,5.36-10.17,10.25-12.63,4.91-2.46,9.98-3.68,15.24-3.68,8.71,0,14.91,1.91,18.59,5.73,3.04,3.18,4.81,7.81,5.28,13.93-4.72.7-9.29,1.36-13.76,2.01-7.39,1.05-14.22,2.18-20.54,3.41-6.29,1.21-11.79,2.59-16.47,4.09-6.2,2.08-11.24,4.77-15.12,8.05-3.9,3.27-6.78,7.17-8.65,11.67-1.87,4.5-2.81,9.57-2.81,15.18,0,6.41,1.5,12.28,4.48,17.61,2.98,5.32,7.4,9.59,13.23,12.8,5.85,3.2,12.96,4.79,21.4,4.79,10.52,0,19.29-1.95,26.31-5.88,5.4-3.02,10.29-7.37,14.63-13.07v15.72h22.8v-71.63c0-5.03-.21-9.7-.64-13.99Zm-25.45,37.39c-.18,4.27-.95,8.07-2.32,11.38-.86,2.67-2.53,5.51-5.03,8.53-2.49,3.04-5.81,5.59-10,7.68-4.17,2.08-9.22,3.14-15.12,3.14-4.11,0-7.54-.64-10.33-1.95-2.77-1.29-4.87-3.04-6.31-5.24s-2.16-4.73-2.16-7.62c0-2.51.56-4.72,1.68-6.59,1.13-1.87,2.73-3.53,4.81-4.97,2.09-1.44,4.64-2.69,7.68-3.78,3.1-1.01,6.66-1.93,10.7-2.75,4.01-.84,8.85-1.71,14.46-2.65,3.6-.6,7.66-1.27,12.18-1.99-.06,1.97-.14,4.25-.23,6.8Z" />
            <path d="m988.51,142.18c-3.99,3.18-9.45,4.75-16.37,4.75-9.8,0-17.19-3.41-22.15-10.25-4.97-6.84-7.44-15.82-7.44-26.91,0-7.13,1.03-13.5,3.12-19.12,2.1-5.61,5.34-10.03,9.72-13.23,4.4-3.22,9.98-4.81,16.76-4.81,5.98,0,11.3,1.75,15.98,5.24,4.68,3.49,8.03,8.38,10.05,14.63l25.92-7.01c-2.81-11.54-8.79-20.62-17.99-27.28-9.18-6.66-20.4-10-33.65-10-11.81,0-22,2.65-30.57,7.95-8.55,5.28-15.16,12.57-19.82,21.82-4.64,9.24-7,19.86-7.07,31.8.08,11.75,2.36,22.25,6.86,31.55,4.5,9.29,10.99,16.62,19.5,21.98,8.5,5.38,18.76,8.05,30.79,8.05,13.04,0,24.05-3.18,33.05-9.55s15.32-15.51,18.9-27.4l-26.56-5.94c-2.03,5.98-5.03,10.56-9.02,13.72Z" />
            <path d="m1095.63,109.78l45.25-58.34h-32.5l-42.67,56.18V12.56h-26.36l-.21,155.55h26.58v-56.18l44.6,56.18h34.47l-49.14-58.34Z" />
          </g>
          <g>
            <path
              d="m197.19,138.47c-.47,26.6-43.1,47.56-95.95,47.56-1.17,0-2.34-.02-3.52-.04C43.28,185.03-.48,162.23,0,135.03c.06-2.84.58-5.62,1.58-8.31,5.49,6.11,12.96,11.67,22.18,16.5,19.77,10.31,45.98,16.25,73.81,16.73,1.23.02,2.45.04,3.68.04,9.55,0,18.74-.64,27.44-1.83,1.03,2.22,2.45,4.28,4.22,6.05.37.35.68.66,1.03.97l.04.04.04.04c3.95,3.44,8.99,5.33,14.19,5.33.91,0,1.87-.06,2.78-.18,8.48-1.09,15.49-7.24,17.88-15.64l.45-1.56v-.29c.27-1.38.41-2.82.41-4.26v-1.81c10.7-4.85,19.52-10.9,25.84-17.78,1.13,3.05,1.69,6.19,1.63,9.4Z"
              className="opacity-50"
              style={{
                fill: 'url(#linear-gradient)',
              }}
            />
            <path
              d="m83.07,136.39c0,3.31.72,6.48,2.02,9.32-35.94-2.92-65.65-15.43-78.4-31.93-1.03-1.34-1.97-2.72-2.76-4.13-.93-1.6-1.71-3.23-2.32-4.9C.51,101.71-.05,98.56,0,95.37c.04-2.74.54-5.43,1.48-8.04,2.76,3.11,6.07,6.09,9.85,8.89,1.38,1.03,2.84,2.04,4.36,3.04,1.44.91,2.92,1.83,4.48,2.7,1.17.68,2.37,1.34,3.6,1.98,16.31,8.5,36.95,14.05,59.31,15.98v16.46Z"
              className="stroke-none opacity-80"
              style={{
                fill: 'url(#linear-gradient-2)',
              }}
            />
            <path
              className="stroke-none opacity-80"
              d="m197.19,98.81c-.06,2.84-.58,5.64-1.58,8.33-.58,1.65-1.34,3.27-2.24,4.85-.84,1.42-1.81,2.82-2.88,4.2-4.77,6.03-11.87,11.44-20.78,15.96v-24.56c2.51-1.13,4.92-2.34,7.22-3.6,1.61-.88,3.15-1.79,4.65-2.74,1.48-.93,2.92-1.89,4.28-2.9,3.72-2.69,7.01-5.57,9.81-8.64,1.05,2.96,1.58,6.01,1.52,9.11Z"
              style={{
                fill: 'url(#linear-gradient-3)',
              }}
            />
            <path
              className="stroke-none"
              d="m194.27,63.38v.02c-.04,2.4-.47,4.79-1.32,7.15-.51,1.48-1.13,2.9-1.97,4.38-.76,1.33-1.64,2.63-2.63,3.9-3.14,4.03-7.48,7.83-12.86,11.3-1.71,1.09-3.23,1.99-4.7,2.79-1.6.86-3.29,1.71-5.26,2.61-1.81.88-3.72,1.68-5.61,2.4l-1.87.72v50.02c0,1.01-.14,1.99-.41,2.9v.02c-1.09,3.82-4.23,6.74-8.13,7.25-.45.06-.88.08-1.31.08-2.42,0-4.68-.86-6.51-2.46-.18-.16-.33-.31-.49-.47-1.83-1.85-2.84-4.33-2.84-6.9l-.43-31.37c-.04-2.1-.6-4.21-1.81-5.88,0-.02-.02-.02-.02-.04-2.18-3-5.71-4.97-9.7-4.97-6.35,0-11.55,4.97-11.94,11.18-.04.25-.04.53-.04.78v18.02c0,2.81-1.21,5.48-3.33,7.37-1.13.99-2.44,1.7-3.82,2.08-.88.23-1.79.37-2.71.37-.43,0-.84-.02-1.27-.08-.37-.04-.72-.12-1.07-.21-4.33-1.07-7.48-5.18-7.48-9.96v-28.41l-2.77-.14c-22.58-1.05-43.98-6.06-60.31-14.07-1.54-.76-3.22-1.62-5.03-2.63-1.89-1.13-3.41-2.08-4.79-2.98-5.44-3.64-9.82-7.58-12.96-11.71-.99-1.31-1.81-2.55-2.55-3.88-.78-1.32-1.38-2.63-1.89-3.96l-.18-.49c-.94-2.67-1.4-5.4-1.34-8.09.27-15.9,17.75-29.91,43.8-37.82-.66,2.26-.23,4.83,1.52,6.82l17.3,19.7c3.86,4.38,9.92,6.14,15.53,4.5l46.2-14.58c5.09-1.48,6.9-7.76,3.39-11.73l-8.63-9.84c41.76,5.67,72.62,24.53,72.23,46.28Z"
              style={{
                fill: 'url(#linear-gradient-4)',
              }}
            />
            <path
              className="stroke-none"
              d="m115.24,20.94v6.68c-.18,1.91-1.46,3.68-3.53,4.29l-29.13,8.57c-3.27.95-6.8-.08-9.06-2.63l-12.49-14.22c-1.11-1.27-1.11-4.36-1.11-4.36v-.37l-.1-3.98-.02-.76v-.23c-.12-2.16,1.23-4.31,3.55-5.01L92.48.37c3.25-.97,6.76.04,9.02,2.57t.04.04l11.54,13.13.95,1.09c.97,1.11,1.34,2.46,1.21,3.74Z"
              style={{
                fill: 'url(#linear-gradient-5)',
              }}
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export const LogoIcon = ({ className, ...props }: ComponentProps<'svg'>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 433.56 409"
      className={cn('w-full h-full', className)}
      {...props}
    >
      <title>Logo icon</title>
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="-122.34"
          y1="471.76"
          x2="451.02"
          y2="206.85"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#ff2e92" />
          <stop offset="1" stopColor="#ff31c5" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="-166.16"
          y1="376.93"
          x2="407.21"
          y2="112.02"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-3"
          x1="-116.92"
          y1="483.49"
          x2="456.44"
          y2="218.59"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="-167.02"
          y1="375"
          x2="406.31"
          y2="110.11"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="131.46"
          y1="44.89"
          x2="253.42"
          y2="44.89"
          xlinkHref="#linear-gradient"
        />
      </defs>
      <g id="_Слой_1" data-name="Слой 1">
        <g className="stroke-none">
          <path
            className="opacity-50"
            d="m433.56,304.44c-1.03,58.48-94.76,104.56-210.95,104.56-2.57,0-5.13-.04-7.74-.09C95.15,406.82-1.06,356.68,0,296.87c.13-6.25,1.28-12.36,3.47-18.27,12.06,13.43,28.49,25.67,48.77,36.28,43.47,22.67,101.09,35.72,162.27,36.79,2.7.04,5.39.09,8.09.09,21.01,0,41.2-1.41,60.32-4.02,2.27,4.88,5.39,9.41,9.28,13.3.81.77,1.5,1.45,2.27,2.14l.09.09.09.09c8.68,7.57,19.76,11.72,31.19,11.72,2.01,0,4.11-.13,6.12-.38,18.65-2.4,34.05-15.91,39.32-34.4l.98-3.42v-.64c.6-3.04.9-6.2.9-9.37v-3.98c23.53-10.65,42.91-23.96,56.81-39.1,2.48,6.72,3.72,13.6,3.59,20.66Z"
            style={{
              fill: 'url(#linear-gradient)',
            }}
          />
          <path
            className="opacity-80"
            d="m182.64,299.87c0,7.27,1.58,14.25,4.45,20.49-79.02-6.42-144.34-33.93-172.37-70.2-2.27-2.95-4.32-5.99-6.08-9.07-2.05-3.51-3.76-7.1-5.09-10.78C1.12,223.63-.12,216.7,0,209.68c.09-6.03,1.2-11.94,3.25-17.67,6.08,6.85,13.35,13.39,21.65,19.55,3.04,2.27,6.25,4.49,9.58,6.67,3.17,2.01,6.42,4.02,9.84,5.95,2.57,1.5,5.22,2.95,7.91,4.36,35.85,18.7,81.24,30.89,130.4,35.12v36.19Z"
            style={{
              fill: 'url(#linear-gradient-2)',
            }}
          />
          <path
            className="opacity-80"
            d="m433.56,217.26c-.13,6.25-1.28,12.41-3.47,18.31-1.28,3.64-2.95,7.19-4.92,10.65-1.84,3.12-3.98,6.2-6.33,9.24-10.48,13.26-26.1,25.16-45.69,35.08v-53.99c5.52-2.48,10.82-5.13,15.87-7.91,3.55-1.93,6.93-3.94,10.22-6.03,3.25-2.05,6.42-4.15,9.41-6.37,8.17-5.9,15.4-12.24,21.56-18.99,2.31,6.5,3.47,13.22,3.34,20.02Z"
            style={{
              fill: 'url(#linear-gradient-3)',
            }}
          />
          <path
            d="m427.12,139.35v.04c-.09,5.27-1.03,10.54-2.91,15.72-1.11,3.26-2.49,6.38-4.33,9.64-1.67,2.91-3.6,5.78-5.78,8.57-6.9,8.87-16.45,17.22-28.27,24.85-3.77,2.4-7.11,4.37-10.33,6.13-3.51,1.89-7.24,3.77-11.57,5.74-3.98,1.93-8.18,3.68-12.34,5.27l-4.11,1.59v109.97c0,2.23-.3,4.37-.9,6.38v.04c-2.4,8.4-9.3,14.82-17.86,15.94-.99.13-1.93.17-2.87.17-5.31,0-10.28-1.89-14.31-5.4-.39-.34-.73-.69-1.07-1.03-4.03-4.07-6.25-9.51-6.25-15.17l-.94-68.97c-.09-4.63-1.33-9.25-3.98-12.94,0-.04-.04-.04-.04-.09-4.8-6.6-12.55-10.92-21.33-10.92-13.97,0-25.4,10.92-26.26,24.59-.09.56-.09,1.16-.09,1.71v39.63c0,6.17-2.66,12.04-7.33,16.19-2.49,2.18-5.36,3.73-8.4,4.58-1.93.51-3.94.81-5.96.81-.94,0-1.84-.04-2.78-.17-.81-.09-1.59-.26-2.36-.47-9.51-2.36-16.45-11.4-16.45-21.89v-62.46l-6.08-.3c-49.65-2.31-96.69-13.32-132.59-30.93-3.38-1.67-7.07-3.56-11.05-5.78-4.16-2.48-7.5-4.58-10.54-6.55-11.95-8.01-21.59-16.67-28.49-25.75-2.18-2.87-3.98-5.61-5.61-8.53-1.71-2.91-3.04-5.78-4.16-8.7l-.39-1.07c-2.06-5.87-3.08-11.87-2.96-17.78.6-34.96,39.03-65.76,96.31-83.15-1.46,4.97-.51,10.62,3.34,14.99l38.04,43.31c8.48,9.64,21.81,13.5,34.14,9.9l101.58-32.05c11.18-3.26,15.17-17.05,7.45-25.79l-18.98-21.63c91.81,12.47,159.67,53.94,158.81,101.75Z"
            style={{
              fill: 'url(#linear-gradient-4)',
            }}
          />
          <path
            d="m253.36,46.04v14.69c-.38,4.2-3.21,8.1-7.75,9.43l-64.05,18.85c-7.2,2.1-14.95-.17-19.92-5.78l-27.46-31.27c-2.44-2.78-2.44-9.6-2.44-9.6v-.81l-.21-8.74-.04-1.67v-.51c-.26-4.76,2.7-9.47,7.8-11.01L203.32.8c7.15-2.14,14.87.09,19.84,5.66t.09.09l25.36,28.87,2.1,2.4c2.14,2.44,2.96,5.4,2.66,8.23Z"
            style={{
              fill: 'url(#linear-gradient-5)',
            }}
          />
        </g>
      </g>
    </svg>
  )
}
